import React from "react";
import { Link } from "gatsby";
import { ContentFaqsPType } from "../../assets/Types/AcfGroupTypes/VsFaqPContainer.type";
import RightArrow from "../../components/SVGIcons/RightArrow/RightArrow";

import styles from "./VsFaqPContainer.module.scss";

interface VsFaqPContainerProps {
  contentFaqsP: ContentFaqsPType;
  isVsContent: boolean;
}
const VsFaqPContainer: React.FC<VsFaqPContainerProps> = ({
  contentFaqsP,
  isVsContent = true,
}) => {
  return (
    <section
      className={`pad-top--large margin-top--large pad-bottom--large ${styles.sectionContainer}`}
    >
      <div
        className={`full-width ${styles.faqGrid} ${
          !isVsContent ? styles.CompetitorFaqGrid : ""
        }`}
      >
        <div
          className={`${styles.faqTitle} ${
            !isVsContent ? styles.CompetitorFaqTitle : ""
          }`}
        >
          <h2 data-testid="h2:contentFaqsTitle">
            {contentFaqsP.contentFaqsTitle}
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: contentFaqsP.contentFaqsSubcopy,
            }}
          />
        </div>

        <div className={!isVsContent ? styles.CompetitorFaqListGrid : ""}>
          {contentFaqsP?.contentFaqsList?.map(faq => (
            <div key={faq.id} className={styles.faq}>
              <div className={styles.faq}>
                <h3>{faq.title}</h3>
                <div>
                  <div
                    className={styles.faqContent}
                    dangerouslySetInnerHTML={{
                      __html: faq.faqsC?.questionsAnswer,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          {isVsContent && (
            <p data-testid="p:faqLinkText" className={styles.faqReadAll}>
              <Link to="/features/faqs/" aria-label="Read all FAQS">
                <span className={styles.faqLinkText}>Read all FAQs</span>
                <span className={styles.faqArrowIcon}>
                  <RightArrow />
                </span>
              </Link>
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default VsFaqPContainer;
